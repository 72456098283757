import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Loader from "@/components/loader";
import VueMultiselect from "@/components/vue-multiselect";
import axios from "../../../../axios/osint.js";
import toolsHelper from "@/mixins/toolsHelper";
import {mapActions, mapGetters, mapMutations} from "vuex";
import whatsappServices from "@/views/research/tools/whatsapp/services.js";
import neoAnalyse from "@/components/analyse";
import Input from "@/components/input";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";

export default {
    name: "neo-facebook",
    components: {
        "analysis-card": analysisCard,
        "neo-button": Button,
        "neo-loader": Loader,
        VueMultiselect,
        "neo-button": Button,
        neoAnalyse,
        "neo-input": Input,
    },
    mixins: [toolsHelper],
    props: {
        toolData: Array,
        toolSearchQueries: Array,
    },
    data() {
        return {
            resultFound: false,
            showResult: false,
            countryCodes: [],
            allCountryCodes: null,
            searchedNumbers: [],
            imgUrl: null,
            request_id: null,
            tabName: null,
            phoneNumber: {
                number: null,
                countryCode: null,
            },
            loadingResult: false,
            about: null,
            allToolsNumbers: null,
            phone: null,
            businessDetails: null,
            status: null,
            selectedName: null,
            inputValue: "Phone",
            inputOptions: [
                {
                    group: "Field Type",
                    categories: ["Phone", "Name", "Email", "URL", "FBID"],
                },
            ],
            emailList: [],
            urlList: [],
            phoneList: [],
            searchKeyword: "",
            isQueryValid: false,
            showResult: false,
            selectVal: "",
            selectNameVal: "",
            searchClicked: false,
            tabName: "",
            request_id: "",
            selectedItem: null,
            facebookId: null,
            nameKeyword: "",
            firstName: "",
            lastName: "",
            facebookData: [],
            isLoading: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseEmails", "getSelectedToolQuery", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        query() {
            return this.selectedItem?.value || `${this.firstName} ${this.lastName}`;
        },
    },
    watch: {
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },
    },
    async mounted() {
        this.tabName = this.$route.name;
        await this.setCountryCodes();
        this.case_id = this.$route.params.id;
        const dataRes = this.getToolsData(this.tabName, "facebook");
        this.$nextTick(function () {
            document.getElementById("case-list").addEventListener("scroll", this.onScroll, false);
        });
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "facebook-tool");
        document.getElementById("case-list").removeEventListener("scroll", this.onScroll);
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "getToolsStatus"]),
        ...mapActions(["getAllCasePhones"]),

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        setName() {
            this.nameKeyword = this.selectedName.value;
        },
        setTheCustomName(value) {
            this.nameKeyword = value;
            this.selectedName = {
                value: value,
            };
        },

        async setCountryCodes() {
            this.allCountryCodes = await whatsappServices.getAllCountryCodes();
            this.allCountryCodes.forEach((res) => {
                this.countryCodes.push({
                    res,
                    value: res.country_with_code,
                });
            });
        },
        setInput() {
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },
        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },
        close(type) {
            if (type == "email") {
                this.selectedItem = {
                    value: this.selectVal.trim(),
                };
                this.searchKeyword = this.selectVal.trim();
                this.$refs["analysis-tool-input"].search = this.selectVal;
            } else {
                this.selectedItem = {
                    value: this.selectVal.trim(),
                };
                this.searchKeyword = this.selectVal.trim();
                this.$refs["analysis-tool-input"].search = this.selectVal;
            }
        },
        handleNumber(event) {
            this.phoneNumber.number = {
                value: event,
            };
        },
        handleChange(event, type) {
            if (type == "name") {
                if (event != this.selectNameVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectNameVal = event;
            } else {
                if (event != this.selectVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectVal = event;
            }
        },

        getCountryCodes() {
            return [
                {
                    group: "Country Codes",
                    categories: this.countryCodes,
                },
            ];
        },
        getSearchedNumbers() {
            return [
                {
                    group: "Numbers",
                    categories: this.searchedNumbers,
                },
            ];
        },

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.urlList = await this.getDropdownData("url");
            this.phoneList = await this.getDropdownData("phone");
            let data = [];
            this.phoneList.forEach((res) => {
                data.push({
                    value: res.value,
                    visited: res.visited,
                });
            });
            this.allToolsNumbers = this.phoneList;
            this.searchedNumbers = data;
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        updateFieldType() {
            this.searchKeyword = "";
            this.selectedItem = "";
            this.selectedName = null;
            this.phoneNumber.number = null;
            this.phoneNumber.countryCode = null;
            this.facebookId = null;
            this.firstName = "";
            this.lastName = "";
        },
        clearFields() {
            this.searchKeyword = "";
            if (this.$refs["analysis-tool-input"]?.search) this.$refs["analysis-tool-input"].search = "";
            this.selectedItem = null;
            this.selectedName = null;
            this.phoneNumber.number = null;
            this.phoneNumber.countryCode = null;
            this.firstName = "";
            this.lastName = "";
            this.facebookId = null;
            this.selectVal = null;
        },
        manualSearch() {
            this.search();
        },
        async search() {
            this.facebookData = [];
            const url = "/intel/facebook-search";
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            let bodyData = {};
            let searchType = "";
            if (this.inputValue == "Email") {
                if (this.selectedItem && emailRegex.test(this.selectedItem.value)) {
                    bodyData = {
                        email: this.selectedItem?.value ? this.selectedItem.value : "",
                    };
                    searchType = "email";
                    this.$emit("searched", {data: {email: this.searchKeyword}, source: "facebook", searchType: searchType});
                    await this.setSearchedFacebookUser(this.searchKeyword, "email");
                } else {
                    this.isLoading = false;
                    this.$toast.error("Please enter valid emailId");
                    return;
                }
            } else if (this.inputValue == "Phone" && this.phoneNumber?.number?.value) {
                // if(this.phoneNumber?.number?.value && !this.phoneNumber.countryCode) {
                //     this.$toast.error("Please select country code");
                //     return;
                // } else if(this.phoneNumber.countryCode && !this.phoneNumber?.number?.value){
                //     this.$toast.error("Please enter phone number");
                //     return;
                // }
                // if (!this.validatePhone()) {
                //     this.$toast.error("Please enter a valid phone number")
                //     this.showResult = false
                //     return
                // }
                if (this.phoneNumber.countryCode)
                    bodyData = {
                        id: this.phoneNumber.countryCode ? this.phoneNumber.countryCode.res._id : "",
                        number: this.phoneNumber.number?.value ? this.phoneNumber.number.value : "",
                    };
                else
                    bodyData = {
                        number: this.phoneNumber.number?.value ? this.phoneNumber.number.value : "",
                    };
                searchType = "phone";
                let phone = (this.phoneNumber.countryCode?.value).replace(/\D/g, "");
                this.$emit("searched", {data: {phone: "+" + phone + this.phoneNumber.number.value}, source: "facebook", searchType: searchType});
                await this.setSearchedNumber("phone");
            } else if (this.inputValue == "URL") {
                if (this.selectedItem && urlRegex.test(this.searchKeyword)) {
                    bodyData = {
                        fb_url: this.selectedItem?.value ? this.selectedItem.value : "",
                    };
                    searchType = "url";
                    this.$emit("searched", {
                        data: {url: this.searchKeyword},
                        source: "facebook",
                        searchType: "url",
                        type: searchType, // NOTE: chnage this to subject key later on
                    });
                    await this.setSearchedFacebookUser(this.searchKeyword, "url");
                } else {
                    this.$toast.error("Please enter valid URL");
                    return;
                }
            } else if (this.inputValue == "FBID" && this.facebookId) {
                bodyData = {
                    fb_id: this.facebookId ? this.facebookId : "",
                };
                searchType = "fbid";
                this.$emit("searched", {data: {fbId: this.facebookId}, source: "facebook", searchType: searchType});
            } else if (this.inputValue == "Name") {
                if (this.firstName) {
                    bodyData = {
                        fname: this.firstName ? this.firstName : "",
                        lname: this.lastName ? this.lastName : "",
                    };
                    searchType = "name";
                    this.$emit("searched", {data: {name: this.lastName ? this.firstName + " " + this.lastName : this.firstName}, source: "facebook", searchType: searchType});
                } else {
                    this.$toast.error("Please enter first name");
                    return;
                }
            }
            if (bodyData) {
                this.isLoading = true;
                const {data} = await axios.post(url, bodyData, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                if (data.data) {
                    this.facebookData = data.data;
                    this.SET_ALL_TOOLS_DATA({
                        ...this._data,
                        "tool-name": "facebook-tool",
                    });
                } else this.$toast.error(data.message);
                this.request_id = data?.query_id;
                this.isLoading = false;
                this.redirectTo();
                this.$emit("searchResult", {data: this.facebookData, source: "facebook", searchType: searchType});
            } else this.$toast.error("Please enter valid input");
        },
        onSelectFieldType() {
            this.selectedItem = null;
            if (this.$refs["analysis-tool-input"]?.search) this.$refs["analysis-tool-input"].search = "";
            this.selectVal = "";
        },
        onSelect(event, type) {
            if (type == "name") {
                this.selectNameVal = event.value;
                this.$refs["analysis-tool-input-" + type].search = event.value;
            } else if (type == "email" || type == "url") {
                this.selectVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            }
        },
        async setSearchedFacebookUser(searchKeyword, val_type) {
            const data = {
                value: searchKeyword,
                platform: "facebook",
                val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let type = val_type;
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) {
                dataItem["visited"] = true;
                this.handleChecked(data);
            } else if (data?.value) {
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
        },
        removeSpecialCharaters(character) {
            if (character) return character.replace(/['"']+/g, "");
        },
        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "facebook", [...this.emailList, ...this.urlList], data);
            allChecked = [...this.emailList, ...this.urlList]?.length ? ([...this.emailList, ...this.urlList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },
        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                const type = Object.keys(selected_query?.query)[0];
                switch (type) {
                    case "email":
                        this.searchEmail = Object.values(selected_query?.query)[0];
                        this.selectedItem = {
                            value: Object.values(selected_query?.query)[0],
                        };
                        this.inputValue = "Email";
                        this.searchType = "email";
                        this.selectVal = Object.values(selected_query?.query)[0];
                        break;
                    case "country_code":
                    case "phone":
                        this.phoneNumber.countryCode = {
                            res: {
                                _id: selected_query.query.id,
                            },
                            value: selected_query.query?.country_with_code,
                        };
                        this.phoneNumber.number = {value: selected_query.query.number};
                        this.inputValue = "Phone";
                        this.searchType = "phone";
                        this.selectNameVal = Object.values(selected_query?.query)[0];
                        break;
                    case "fname":
                        this.fname = Object.values(selected_query?.query)[0];
                        this.fname = Object.values(selected_query?.query)[0];
                        this.lname = Object.values(selected_query?.query)[1] ? Object.values(selected_query?.query)[1] : "";
                        this.inputValue = "Name";
                        this.searchType = "name";
                        break;
                    case "fb_id":
                        this.facebookId = Object.values(selected_query?.query)[0];
                        this.inputValue = "FBID";
                        break;
                    case "fb_url":
                        this.selectedItem.value = Object.values(selected_query?.query)[0];
                        this.inputValue = "URL";
                        break;
                    default:
                        break;
                }
            } else {
                this.redirectTo();
            }
        },
        async setSearchedNumber(val_type) {
            const data = {
                value: this.phoneNumber.number.value,
                platform: "facebook",
                val_type: "phone",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            let type = val_type;
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === this.phoneNumber.number.value;
            });
            if (dataItem) {
                dataItem["visited"] = true;
                this.handleChecked(data);
            } else if (data?.value) {
                console.log(data);
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
        },
        setSearch() {
            this.$refs["analysis-tool-input"].search = this.phoneNumber.number.value;
        },
        validatePhone() {
            let number = this.phoneNumber.number?.value;
            if (number) {
                number = number.replace(/[^0-9+]/g, "");
                return true;
            } else {
                return false;
            }
        },
    },
};
